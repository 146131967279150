<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Desembolso - Editar</strong>

            <router-link class="btn btn-light float-right btn-sm"  :to="{ path: '/desembolso/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col lg="6">
                  <b-form-group>
                    <label>Cliente: <b-badge @click="ShowModalClient" variant="primary">Nuevo</b-badge></label>
                    <v-select placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="text" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Medio de pago:">
                    <b-form-select v-model="disbursement.payment_method" :options="payment_method"></b-form-select>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo:">
                    <b-form-select v-model="disbursement.id_module" :options="modules"></b-form-select>
                    <small v-if="errors.id_module"  class="form-text text-danger" >Seleccione un Tipo</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro de Referencia:">
                    <b-form-input type="text" v-model="disbursement.code"></b-form-input>
                    <small v-if="errors.code"  class="form-text text-danger" >Ingrese un nro refencia</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input type="date" class="text-center" v-model="disbursement.date"></b-form-input>
                    <small v-if="errors.date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input type="number" step="any" class="text-right" v-model="disbursement.amount"></b-form-input>
                    <small v-if="errors.amount"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Anexos:">
                     <b-form-file accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"></b-form-file>
                    <small v-if="errors.file"  class="form-text text-danger" >Seleccione un documento</small>
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group label="Observación:">
                    <b-form-input v-model="disbursement.description"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4"></b-col>
                <b-col md="2">
                  <b-button type="button" @click="OpenAnnexe" :disabled="disbursement.file.length == 0" class="form-control" variant="warning" ><i class="fas fa-file"></i> Ver Anexo</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";// components
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "DisbursementAdd",
  props: ["id_disbursement"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Disbursement',
      role: 3,
      disbursement: {
          id_disbursement:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_module:'',
          code:'',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          description:'',
          file:'',
          new_file:'',
          amount:'',
          state:1,
          created_at:'',
          updated_at:'',
      },
      file:null,
      modules:[],
      payment_method:[
        {value:'008', text:'EFECTIVO'},
        {value:'007', text:'CHEQUE'},
        {value:'003', text:'TRANSFERENCIA'},
      ],
      client: null,
      clients: [],
      state:[
        {value:'1',text:'Pendiente'},
        {value:'2',text:'Finalizado'},
        {value:'0',text:'Rechazado'},
      ],

      errors: {
        payment_method: false,
        date: false,
        amount: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListModule();
    this.ViewDisbursement();
  },
  methods: {
    ViewDisbursement,
    EditDisbursement,
    Validate,

    SearchClients,
    ShowModalClient,
    onFileChange,
    ListModule,
    OpenAnnexe,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
 
  },
};

function ViewDisbursement() {
  let me = this;
  let id_disbursement = je.decrypt(this.id_disbursement);
  let url = me.url_base + "disbursement/view/"+id_disbursement;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {

        me.disbursement.id_disbursement = response.data.result.id_disbursement;
        me.disbursement.id_client = response.data.result.id_client;
        me.disbursement.id_user = response.data.result.id_user;
        me.disbursement.id_branch_office = response.data.result.id_branch_office;
        me.disbursement.id_module = response.data.result.id_module;
        me.disbursement.code = response.data.result.code;
        me.disbursement.payment_method = response.data.result.payment_method;
        me.disbursement.date = response.data.result.date;
        me.disbursement.description = response.data.result.description;
        me.disbursement.file = response.data.result.file;
        me.disbursement.amount = response.data.result.amount;
        me.disbursement.state = response.data.result.state;
        me.client = { value: response.data.result.id_client, text:response.data.result.name +" - "+ response.data.result.document_number}
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function SearchClients(search, loading) {
  
  let me = this;
   let url = this.url_base + "client/search-actives";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.clients = response.data.result;
           loading(false);
     })
   }
}

function ShowModalClient() {
  EventBus.$emit('ModalClientsShow');
}

function EditDisbursement() {
  let me = this;
  let url = me.url_base + "disbursement/edit";
  me.isLoading = true;
  let data = new FormData();
  data.append("id_disbursement", this.disbursement.id_disbursement);
  data.append("id_client", this.client.value);
  data.append("id_module", this.disbursement.id_module);
  data.append("code", this.disbursement.code);
  data.append("dues", this.disbursement.dues);
  data.append("payment_method", this.disbursement.payment_method);
  data.append("date", this.disbursement.date);
  data.append("description", this.disbursement.description);
  data.append("file", this.disbursement.new_file);
  data.append("amount", this.disbursement.amount);
  data.append("state", this.disbursement.state);
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.disbursement.file = response.data.result.file;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_client = this.client == null ? true : false;
  this.errors.payment_method = this.disbursement.payment_method.length == 0 ? true : false;
  this.errors.date = this.disbursement.date.length == 0 ? true : false;
  this.errors.amount = this.disbursement.amount.length == 0 ? true : false;

  if (this.errors.id_client) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.amount) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el desembolso?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditDisbursement();
    }
  });
}

function onFileChange(e) {
  this.disbursement.new_file = e.target.files[0];
}

function OpenAnnexe() {
  let me = this;
  let url = me.url_base + this.disbursement.file;
  window.open(url,'_blank');
}

function ListModule() {
  let me = this;
  let url = me.url_base + "module/actives";
  me.modules = [{ value: '', text:'- Seleccione una opcion -'}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.modules.push({value: element.id_module, text: element.name });
      });
    }
  });
}

</script>
